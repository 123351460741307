import { LazyExoticComponent } from 'react';

interface ResolveModalComponentParams {
    modalName?: string;
    modals: Record<string, LazyExoticComponent<() => JSX.Element>>;
}

/**
 * Based on the modals passed to this function, return the
 * approprate modal component, or null if none found.
 */
export const resolveModalComponent = ({ modalName, modals }: ResolveModalComponentParams) => {
    if (!modalName) {
        return null;
    }

    if (Object.hasOwn(modals, modalName)) {
        return modals[modalName];
    }

    return null;
};
