import { IconButton } from '@keyliving/component-lib';
import classNames from 'classnames/bind';
import { MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Icon } from '../../../static/k.svg';
import classes from './AuthLayout.module.scss';

const cx = classNames.bind(classes);

interface LogoProps {
    className?: string;
    darkTheme?: boolean;
}

export default function Logo({ className, darkTheme = false }: LogoProps) {
    const navigate = useNavigate();

    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();

        navigate('/');
    };

    return (
        <div className={cx('logo-wrapper', className)}>
            <IconButton
                aria-label="Key"
                className={cx('logo', {
                    'logo--light': !darkTheme,
                    'logo--dark': darkTheme,
                })}
                fill={null}
                icon={<Icon />}
                onClick={handleClick}
                rounded
                size="md"
                theme={null}
            />
        </div>
    );
}
