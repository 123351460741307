import { BarGraphIcon, FolderIcon, HomeIcon, MenuItemProps } from '@keyliving/component-lib';
import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import FullScreenFallbackLayout from '../components/layout/FullScreenFallbackLayout';
import PublicLayout from '../components/layout/PublicLayout';
import Login from '../pages/auth/Login';
import NoMatch from '../pages/NoMatch';
import { URLS } from './urls';

const Asset = lazy(() => import('../pages/Assets/Asset'));
const Assets = lazy(() => import('../pages/Assets/Assets'));
const ClaimAccount = lazy(() => import('../pages/auth/ClaimAccount'));
const Documents = lazy(() => import('../pages/Documents'));
const ForgotPassword = lazy(() => import('../pages/auth/ForgotPassword'));
const ForgotPasswordSuccess = lazy(() => import('../pages/auth/ForgotPasswordSuccess'));
const Portfolio = lazy(() => import('../pages/Portfolio'));
const NewPassword = lazy(() => import('../pages/auth/NewPassword'));

/**
 * These are public routes that the app needs. Because we currently only show the menu to
 * users who are logged in, there is no need for these to be in the menu. These are used
 * in App.tsx to create these routes.
 */
export const publicRoutes: RouteObject[] = [
    {
        element: <PublicLayout />,
        children: [
            {
                path: URLS.Login.path,
                element: <Login />,
            },
            {
                path: URLS.ForgotPassword.path,
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <ForgotPassword />
                            </Suspense>
                        ),
                    },
                    {
                        path: URLS.ForgotPassword.Success.path,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <ForgotPasswordSuccess />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: URLS.NewPassword.path,
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <NewPassword />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: '*',
                element: <NoMatch />,
            },
        ],
    },
    {
        path: URLS.ClaimAccount.path,
        element: (
            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                <ClaimAccount />
            </Suspense>
        ),
    },
];

/**
 * These are routes for logged in users only. We use this array to
 * build our menu and wrap in a PrivateRoute to create the
 * protected routes in App.tsx.
 */
export const authRoutes: MenuItemProps[] = [
    {
        path: '/',
        label: 'Home',
        icon: <HomeIcon height={24} width={24} />,
        showInMenu: false,
        index: true,
        element: <Navigate to={URLS.Portfolio.path} />,
    },
    {
        path: URLS.Documents.path,
        label: 'Documents',
        showInMenu: true,
        icon: <FolderIcon height={24} width={24} />,
        element: (
            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                <Documents />
            </Suspense>
        ),
    },
    {
        path: URLS.Assets.path,
        label: 'Homes',
        showInMenu: true,
        icon: <HomeIcon height={24} width={24} />,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <Assets />
                    </Suspense>
                ),
            },
            {
                path: URLS.Assets.Detail.path,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <Asset />
                    </Suspense>
                ),
            },
        ],
    },
    {
        path: URLS.Portfolio.path,
        label: 'Portfolio',
        showInMenu: true,
        icon: <BarGraphIcon height={24} width={24} />,
        element: (
            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                <Portfolio />
            </Suspense>
        ),
    },
];
