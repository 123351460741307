import { useSideMenu } from '@keyliving/component-lib';
import classnames from 'classnames/bind';

import classes from './Hamburger.module.scss';

const cx = classnames.bind(classes);

interface HamburgerProps {
    className?: string;
    darkTheme?: boolean;
}

export default function Hamburger({ className, darkTheme }: HamburgerProps) {
    const { menuOpen, toggleMenuFully } = useSideMenu();

    return (
        <button
            aria-controls="main-menu"
            aria-expanded={menuOpen}
            aria-haspopup
            aria-label="Menu Toggle"
            className={cx(
                'toggle',
                {
                    'toggle--dark': darkTheme,
                },
                className
            )}
            onClick={() => {
                toggleMenuFully();
            }}
            type="button"
        >
            <div
                className={cx(classes['hamburger-wrapper'], {
                    'hamburger-wrapper--transparent': menuOpen,
                })}
            >
                <div
                    className={cx('hamburger', {
                        active: menuOpen,
                    })}
                >
                    <div className={cx('hamburger__layer', 'hamburger__layer--one')} />
                    <div className={cx('hamburger__layer', 'hamburger__layer--two')} />
                    <div className={cx('hamburger__layer', 'hamburger__layer--three')} />
                    <div className={cx('hamburger__layer', 'hamburger__layer--four')} />
                </div>
            </div>
        </button>
    );
}
