import { IconProps } from './types';

export default function BarGraphIcon({ height = 24, width = 20, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 19.97 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M4.33 15.6H1.16a1.2 1.2 0 0 0-1.2 1.2v6a1.2 1.2 0 0 0 1.2 1.2h3.17a1.2 1.2 0 0 0 1.2-1.2v-6a1.19 1.19 0 0 0-1.2-1.2Zm-.29 6.9H1.46v-5.4h2.58ZM11.57 10.8H8.4A1.2 1.2 0 0 0 7.2 12v10.8A1.2 1.2 0 0 0 8.4 24h3.17a1.21 1.21 0 0 0 1.21-1.2V12a1.2 1.2 0 0 0-1.21-1.2Zm-.24 11.7H8.75V12.3h2.58ZM18.78 0H15.6a1.2 1.2 0 0 0-1.2 1.2v21.6a1.2 1.2 0 0 0 1.2 1.2h3.18a1.2 1.2 0 0 0 1.2-1.2V1.2a1.2 1.2 0 0 0-1.2-1.2Zm-.31 22.5H15.9v-21h2.57Z"
                fill="currentColor"
            />
        </svg>
    );
}
