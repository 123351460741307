import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { customBaseFetch } from './lib';

export const api = createApi({
    reducerPath: 'api',
    baseQuery: customBaseFetch({
        baseUrl: process.env.REACT_APP_API_SERVICE_ENDPOINT,
    }),
    endpoints: () => ({}),
    tagTypes: [
        'Asset',
        'AssetImages',
        'AssetValues',
        'Document',
        'Equity',
        'InvoiceLineItem',
        'RepairMaintenance',
    ],
});

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: customBaseFetch({
        baseUrl: process.env.REACT_APP_IDENTITY_SERVICE_ENDPOINT,
    }),
    endpoints: () => ({}),
    tagTypes: ['User'],
});
