import { LoadingRing, Modal } from '@keyliving/component-lib';
import classNames from 'classnames/bind';
import { Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';

import useAuth from '../../hooks/useAuth';
import { APP_MODAL_PARAMS } from '../../routes/urls';
import classes from './AppModals.module.scss';
import { allModals, PUBLIC_MODALS } from './constants';
import { resolveModalComponent } from './lib';

const cx = classNames.bind(classes);

export default function AppModals() {
    const [{ modal }] = useTypedSearchParams(APP_MODAL_PARAMS);
    const [, setSearchParams] = useSearchParams();
    const { isLoggedIn } = useAuth();
    const modals = isLoggedIn ? allModals : PUBLIC_MODALS;
    const ContentComponent = resolveModalComponent({
        modalName: modal,
        modals,
    });

    if (ContentComponent === null) {
        return null;
    }

    return (
        <Modal
            className={cx(`modal--${modal}`)}
            id={classes['app-modal']}
            noPadding={['welcome'].includes(modal ?? '')}
            onClose={() => {
                setSearchParams((params) => {
                    params.delete('modal');
                    return params;
                });
            }}
            showModal
        >
            <Suspense fallback={<LoadingRing />}>
                <ContentComponent />
            </Suspense>
        </Modal>
    );
}
