import { CONTRACT_CONDITION_TYPE, PRODUCT_TYPE } from '@keyliving/shared-types';

export const REQUIRED_MSG = 'This field is required';
export const SUPPORT_EMAIL = 'info@keyown.com';
export const COMMUNITY_EMAIL = 'community@keyown.com';

export const EQUITY_PRODUCTS = [
    PRODUCT_TYPE.INITIAL_INVESTMENT,
    PRODUCT_TYPE.EQUITY_BOOST,
    PRODUCT_TYPE.AUTO_EQUITY_PURCHASE,
    PRODUCT_TYPE.EQUITY_PURCHASE,
];

export const VALUATION_METHOD_CONDITIONS = [
    CONTRACT_CONDITION_TYPE.HOME_PRICE_INDEX_VALUATION_METHOD,
    CONTRACT_CONDITION_TYPE.FIXED_GROWTH_VALUATION_METHOD,
];
