import { isSameMonth } from 'date-fns';
import { useMemo } from 'react';

import { useGetOwnerInvoiceLineItemsQuery } from '../redux/modules/owner';
import generateUtcYtdFilterOptions from '../utils/generateUtcYtdFilterOptions';
import parseIsoString from '../utils/parseIsoString';

const now = new Date();
/**
 * The date we launched the feature of approving Owner Mirror invoices.
 * Before this date, we don't have data.
 */
const launchDateMs = Date.UTC(2023, 9, 1); // Oct 1, 2023

export default function useYtdFilterOptions(ownerId: string) {
    const { data, isLoading } = useGetOwnerInvoiceLineItemsQuery({
        startDateIso: new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1)).toISOString(),
        endDateIso: new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 1)).toISOString(),
        ownerId,
    });

    const options = useMemo(() => {
        const allOptions = generateUtcYtdFilterOptions().filter((option) => {
            /**
             * We currently only have data as far back as Oct 1, 2023.
             * So filter out options before that time until we have
             * historical data.
             */
            const [startDateIso] = option.value.split(',');
            const startDateMs = parseIsoString(startDateIso).getTime();

            return startDateMs >= launchDateMs;
        });

        /**
         * Data will either be an array of line items with the status
         * of APPROVED_FOR_REMITTANCE or PAID. If no line items are
         * found, an empty array is returned.
         */
        if (!data || data.length === 0) {
            // We don't have any data for the current month, so remove it.
            return allOptions.filter((option) => {
                const [startDateIso] = option.value.split(',');
                const startDate = parseIsoString(
                    startDateIso,
                    ({ month, year }) => new Date(year, month, 1)
                );

                return !isSameMonth(now, startDate);
            });
        }

        // Options including the current month
        return allOptions;
    }, [data]);

    return { isLoading, options };
}
