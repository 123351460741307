import { useGlobalAuth } from '@keyliving/component-lib';
import { ORGS } from '@keyliving/shared-types';
import { isOrg, isTokenExpired } from '@keyliving/utils';
import { useMemo } from 'react';

import { clearAuthDetails, selectAuthData } from '../redux/modules/auth';
import { useAppDispatch, useAppSelector } from './redux';

export default function useAuth() {
    const { claims, token, user } = useAppSelector(selectAuthData);
    const dispatch = useAppDispatch();
    const { deleteToken: deleteAuthToken } = useGlobalAuth();
    const tokenExpired = isTokenExpired(claims?.exp ?? null);

    const org = useMemo<ORGS | null>(() => {
        const orgs = user?.org_codes;

        if (!orgs?.length) {
            return null;
        }

        const firstOrg = orgs[0];

        if (isOrg(firstOrg)) {
            return firstOrg;
        }

        return null;
    }, [user]);

    const signOut = () => {
        dispatch(clearAuthDetails());
        deleteAuthToken();
        sessionStorage.clear();
        return Promise.resolve();
    };

    return {
        user,
        claims,
        token,
        isLoggedIn: !!user && !tokenExpired,
        signOut,
        org,
    };
}
