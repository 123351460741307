import { SideMenu, SideMenuExpandCollapse } from '@keyliving/component-lib';
import classNames from 'classnames/bind';
import { ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAnalytics, useAuth } from '../../../hooks';
import { authRoutes } from '../../../routes/routes';
import classes from './AuthLayout.module.scss';
import Hamburger from './Hamburger';
import Logo from './Logo';
import ProfileMenuItem from './ProfileMenuItem';

const cx = classNames.bind(classes);

interface AuthLayoutProps {
    children?: ReactNode;
}

export default function AuthLayout({ children }: AuthLayoutProps) {
    const { signOut, user } = useAuth();
    const navigate = useNavigate();
    const { trackEvent } = useAnalytics();
    const { hostname } = window.location;
    const isDev = hostname.includes('staging') || hostname.includes('localhost');

    const utilityItems = useMemo(() => {
        return [<ProfileMenuItem key="profile" user={user} />];
    }, [user]);

    const handleSignOut = () => {
        signOut().then(() => {
            trackEvent('logout');
            navigate('/');
        });
    };

    return (
        <>
            <Logo darkTheme={isDev} />

            <SideMenu
                darkTheme={isDev}
                defaultMenuOpen
                id={classes['side-menu']}
                menuRoutes={authRoutes}
                onSignOut={handleSignOut}
                renderExpandCollapse={({ menuExpanded }) => {
                    return (
                        <SideMenuExpandCollapse
                            className={cx(classes['expand-collapse'], {
                                [classes['expand-collapse--can-vanish']]: !menuExpanded,
                            })}
                        />
                    );
                }}
                utilityMenuItems={utilityItems}
            >
                {children}

                <Hamburger darkTheme={isDev} />
            </SideMenu>
        </>
    );
}
