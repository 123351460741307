import { enum as znum, infer as zinfer } from 'zod';

/**
 * Tabs
 *
 * Best solution for now until we can improve the "Tabs" component to
 * use a generic for validating the tabId and onChangeTab prop
 *
 * TODO: improve the "Tabs" component to use generics
 */
export const ASSET_TABS = ['performance', 'repair-reports'] as const;
export const assetTabs = znum(ASSET_TABS);
export type AssetTab = zinfer<typeof assetTabs>;
