import { Link, Outlet } from 'react-router-dom';

import logo from '../../../static/key-logo.svg';
import classes from './PublicLayout.module.scss';

export default function PublicLayout() {
    return (
        <div className={classes.wrapper}>
            <div className={classes['logo-wrapper']}>
                <Link to="/">
                    <img alt="Key" className={classes.logo} src={logo} />
                </Link>
            </div>
            <div className={classes.content}>
                <Outlet />
            </div>
        </div>
    );
}
