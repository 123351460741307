import { Avatar, MenuItem } from '@keyliving/component-lib';
import { Owner } from '@keyliving/shared-types';
import classNames from 'classnames/bind';

import classes from './AuthLayout.module.scss';

const cx = classNames.bind(classes);

interface ProfileMenuItemProps {
    className?: string;
    user: Owner | null;
}

export default function ProfileMenuItem({ className, user }: ProfileMenuItemProps) {
    return (
        <MenuItem
            className={cx(classes['profile-menuitem'], className)}
            icon={<Avatar name={user?.name || ''} size="md" />}
            label={user?.name}
            path="/"
        />
    );
}
