/**
 * The below ApplicationData types are an attempt to give structure
 * to the JSON data that is saved in the DB. It's by
 * no means exhaustive and you may find discrepencies.
 *
 * From here...
 */

export enum APPLICATION_STATUS {
    IN_PROGRESS = 'In Progress',
    PENDING_LETTER_OF_EMPLOYMENT = 'Pending Income Proof Documents',
    SUBMITTED = 'Application Submitted',
    PRE_APPROVED = 'Application Pre-Approved',
    APPROVED = 'Application Approved',
    NO_CURRENT_APPLICATION = 'No Current Application',
    CANCELLED = 'No Current Application',
    COMPLETED = 'Application Complete',
}

export enum APPLICATION_STAGES {
    EMPTY = '', // default is empty string
    MOVE_IN_DATE = 'MOVE_IN_DATE',
    FIRST_TIME_BUYER = 'FIRST_TIME_BUYER',
    ADDITIONAL_RESIDENTS = 'ADDITIONAL_RESIDENTS',
    PETS = 'PETS',
    PARKING = 'PARKING',
    TENANT_INSURANCE = 'TENANT_INSURANCE',
    UPLOAD = 'UPLOAD',
    UPLOAD_SUCCESS = 'UPLOAD_SUCCESS',
    APPLICATION_READY = 'APPLICATION_READY',
}

// paths
// /personal
// /living

export interface ApplicationDataMeta {
    id: string;
    path: string;
    status: APPLICATION_STATUS;
    currentStage: APPLICATION_STAGES;
    certnRedirectUrl: string;
    isPrimaryApplicant: boolean;
    isCreditCheckConsented: boolean;
    isPrivacyPolicyAccepted: boolean;
    isValidInformationConfirmed: boolean;
}

interface Pet {
    petName: string;
    petType: string;
    petBreed: string;
    petColor: string;
    petNumber: number;
    petWeight: string;
}

interface Vehicle {
    year: string;
    color: string;
    makeModel: string;
    licensePlate: string;
    vehicleNumber: number;
}

type YesNoEmpty = 'Yes' | 'No' | '';

interface AdditionalResident {
    firstName: string;
    lastName: string;
    birthdate: string;
    phoneNumber: string;
    email: string;
    income: string;
    isFinancialContributor: boolean;
    additionalResidentNumber: number;
}

export interface ApplicationDataLiving {
    pets: Pet[];
    vehicles: Vehicle[];
    totalPets: number | string;
    moveInDate: string | null;
    totalVehicles: number | string;
    firstTimeBuyer: YesNoEmpty;
    homeBusinessOwner: YesNoEmpty;
    additionalResidents: AdditionalResident[];
    ownsOtherProperties: YesNoEmpty;
    canceledOrDeniedPolicy: YesNoEmpty;
    insuranceClaimsOrLosses: YesNoEmpty;
    totalAdditionalResidents: number | string;
}

export enum EMPLOYMENT_TYPE {
    EMPTY = '', // default data is empty string
    EMPLOYED = 'EMPLOYED',
    SELF_EMPLOYED = 'SELF_EMPLOYED',
    PART_TIME_OR_CONTRACTOR = 'PART_TIME_OR_CONTRACTOR',
    RETIRED = 'RETIRED',
    STUDENT = 'STUDENT',
    UNEMPLOYED = 'UNEMPLOYED',
    OTHER = 'OTHER',
}

export enum RISK_TOLERANCE {
    EMPTY = '', // default data is empty string
    HIGH_RISK = 'HIGH_RISK',
    MEDIUM_RISK = 'MEDIUM_RISK',
    LOW_RISK = 'LOW_RISK',
}

export enum OBJECTIVE {
    EMPTY = '', // default data is empty string
    LONG_TERM = 'LONG_TERM',
    SHORT_TERM = 'SHORT_TERM',
}

export enum EXPERIENCE {
    EMPTY = '', // default data is empty string
    BEGINNER = 'BEGINNER',
    EXPERIENCED = 'EXPERIENCED',
    EXPERT = 'EXPERT',
}

export enum MARITAL_STATUS {
    EMPTY = '', // default data is empty string
    SINGLE = 'single',
    MARRIED = 'married',
    COMMON_LAW = 'common-law',
    DIVORCED = 'divorced',
    WIDOWED = 'widowed',
    UNDISCLOSED = 'undisclosed',
}

export interface IncomeYear {
    year: string | number; //  string because default data is empty string
    income: string;
}

export interface ApplicationDataFinance {
    message: string;
    subject: string;
    industry: string;
    jobTitle: string;
    objective: OBJECTIVE;
    pepStatus: string;
    companyName: string;
    fixedAssets: string;
    liabilities: string;
    employerName: string;
    liquidAssets: string;
    employerEmail: string;
    incomeSources: string;
    insiderStatus: string;
    riskTolerance: RISK_TOLERANCE;
    employmentType: EMPLOYMENT_TYPE;
    currentYearIncome: IncomeYear;
    lastYearIncome: IncomeYear;
    previousIncome: IncomeYear;
    businessAddress: {
        city: string;
        street: string;
        country: string;
        province: string;
        postalCode: string;
    };
    experienceLevel: EXPERIENCE;
    securitiesDealer: string;
    employmentLetterRecipient: string;
    incomeHistory?: Array<Record<string, any>>; // TODO: This is incomplete, we need to create a type for what Plaid returns.
}

export interface ApplicationDataPersonal {
    city: string;
    idType: string;
    street: string;
    country: string;
    lastName: string;
    province: string;
    birthDate: {
        day: string;
        year: string;
        month: string;
    };
    faceImage: string;
    firstName: string;
    postalCode: string;
    citizenship: string;
    maritalStatus: MARITAL_STATUS;
    idBackSideImage: string;
    idFrontSideImage: string;
    isPlaidAuthorized: boolean;
    isFlinksAuthorized: boolean;
    socialInsuranceNumber: string;
    phoneNumber: string;
}

export interface ApplicationDataLegal {
    agreePrivacyPolicy?: string; // timestamp
    consentCollectInformation?: string; // timestamp
    consentHardCredit?: string; // timestamp
}

/** ...to here */

export interface ApplicationData {
    finance?: ApplicationDataFinance;
    personal?: ApplicationDataPersonal;
    living?: ApplicationDataLiving;
    meta?: ApplicationDataMeta;
    legal?: ApplicationDataLegal;
}

export interface PlaidIncomeTransaction {
    amount: number;
    date: string;
    name: string;
    pending: boolean;
    transactionId: string;
    iso_currency_code: string;
}

export interface PlaidIncomeHistorySummary {
    startDate: string;
    endDate: string;
    transactions: PlaidIncomeTransaction[];
}

export interface PlaidIncomeSource {
    accountId: string;
    incomeSourceId: string;
    incomeCategory: string;
    totalAmount: number;
    transactionCount: number;
    payFrequency: string;
    historySummary: PlaidIncomeHistorySummary[];
}

export interface PlaidBankIncome {
    bankIncomeId: string;
    incomeSources: PlaidIncomeSource[];
}

export interface PlaidApplicationData {
    incomeHistory?: PlaidBankIncome[];
}

export interface ProtectedApplicationCreditCheckData {
    creditCheckId?: string;
    creditCheckStatus?: string;
    equifax_result?: {
        status: string;
    };
}

export interface PlaidIDVerificationData {
    identityVerificationId: string;
    identityVerification?: {
        status: string;
        documentary_verification: {
            status: string;
        };
    };
}
export interface ProtectedApplicationData {
    plaid?: PlaidApplicationData;
    credit_check?: ProtectedApplicationCreditCheckData;
    plaid_id_verification: PlaidIDVerificationData;
}

// DB
export interface Applicant {
    id: string;
    application_form_id: string;
    user_id: string;
    application_data: ApplicationData | null;
    // protected_application_data: ProtectedApplicationData | null; // NOTE: I think this was adde in error. Meant for Application
    is_primary_applicant: boolean;
    created_at: string | null;
    updated_at: string | null;
}

// DB
export enum ApplicationStatus {
    APPROVED = 'APPROVED',
    CANCELLED = 'CANCELLED',
    COMPLETED = 'COMPLETED',
    DECLINED = 'DECLINED',
    IN_PROGRESS = 'IN_PROGRESS',
    OTHER = 'OTHER',
    PENDING_LETTER_OF_EMPLOYMENT = 'PENDING_LETTER_OF_EMPLOYMENT',
    SUBMITTED = 'SUBMITTED',
}

// DB
export interface ApplicationForm {
    id: string;
    created_at: string | null;
    updated_at: string | null;
    status: ApplicationStatus;
    building_suite_id: string | null;
}

// This matches what comes from the GET route after DB joins/manipulation
export interface Application {
    id: string;
    status: ApplicationStatus;
    building_suite_id: string | null;
    applicant_id: string;
    user_id: string;
    application_data: ApplicationData | null;
    is_primary_applicant: boolean;
    created_at: string;
    updated_at: string;
    protected_application_data: ProtectedApplicationData | null;
    org_code: string; // Has to be a string because we don't know if array contains orgs we can acocunt for on the FE. use a type guard to narrow the type.
    applicant_created_at: string;
    applicant_updated_at: string;
}

export enum APPLICATION_DOCUMENT_TYPE {
    GOOGLE_DRIVE_PUBLIC_FOLDER = 'GOOGLE_DRIVE_PUBLIC_FOLDER',
    GOOGLE_DRIVE_PRIVATE_FOLDER = 'GOOGLE_DRIVE_PRIVATE_FOLDER',
    IDENTITY_CHECK_REPORT = 'IDENTITY_CHECK_REPORT',
    LETTER_OF_EMPLOYMENT = 'LETTER_OF_EMPLOYMENT',
    APPLICATION_FORM = 'APPLICATION_FORM',
    INVOICE = 'INVOICE',
    OTHER = 'OTHER',
    PRE_AUTHORIZED_DEBIT_AGREEMENT = 'PRE_AUTHORIZED_DEBIT_AGREEMENT',
    OWNER_RESIDENT_AGREEMENT = 'OWNER_RESIDENT_AGREEMENT',
}

export enum APPLICATION_DOCUMENT_STATUS {
    SUBMITTED = 'SUBMITTED',
    AWAITING_SIGNATURE = 'AWAITING_SIGNATURE',
    DECLINED = 'DECLINED',
    CANCELLED = 'CANCELLED',
    OTHER = 'OTHER',
    PENDING = 'PENDING',
}

export enum APPLICATION_DOCUMENT_SOURCE {
    MANUAL = 'MANUAL',
    CERTN = 'CERTN',
    OTHER = 'OTHER',
    HELLOSIGN = 'HELLOSIGN',
    PLAID = 'PLAID',
}

// DB
export interface BaseApplicationDocument {
    id: string;
    user_id: string;
    external_identifier_id: string | null;
    google_drive_url: string | null;
    source: APPLICATION_DOCUMENT_SOURCE;
    status: APPLICATION_DOCUMENT_STATUS;
    type: APPLICATION_DOCUMENT_TYPE;
    created_at: string | null;
    updated_at: string | null;
}

// This matches what comes from the GET route after DB joins/manipulation
export interface ApplicationDocument extends BaseApplicationDocument {
    external_identifier_id: string | '';
    google_drive_url: string | '';
    createdAt: string;
    updatedAt: string;
}
