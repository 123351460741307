import { Owner } from '@keyliving/shared-types';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { configureScope } from '@sentry/react';

import { setAuthDetails, setCurrentUser } from './authModule';

export const authListenerMiddleware = createListenerMiddleware();

function identifyUser(action: { payload: Owner; type: string }) {
    /**
     * When we set a owner, identify that owner across our
     * various tracking services.
     */
    const { id, org_codes } = action.payload;

    let userOrg: string | null = null;

    if (org_codes && org_codes.length) {
        const [firstOrg] = org_codes;
        userOrg = firstOrg;
    }

    /**
     * Sentry - setup a scope for Sentry that will
     * add details to captured exceptions for that owner.
     */
    configureScope(function (scope) {
        if (userOrg) {
            scope.setTag('org', userOrg);
        }
        scope.setUser({ id });
    });

    // Google Analytics
    window.dataLayer.push({
        user_id: id,
        org: userOrg,
    });
}

authListenerMiddleware.startListening({
    actionCreator: setCurrentUser,
    effect: identifyUser,
});

authListenerMiddleware.startListening({
    actionCreator: setAuthDetails,
    effect: ({ payload, type }) => {
        const { user } = payload;

        if (user) {
            identifyUser({
                payload: user,
                type,
            });
        }
    },
});
