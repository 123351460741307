import { IconProps } from './types';

export default function FilePdfIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M4.499 19.498v-7.5M4.5 12h.75a2.25 2.25 0 0 1 0 4.5H4.5M10.5 19.5a3 3 0 0 0 3-3V15a3 3 0 0 0-3-3ZM16.5 19.5v-6A1.5 1.5 0 0 1 18 12h1.5M16.499 16.498h2.25"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M22.809 5.559a1.5 1.5 0 0 1 .44 1.06V21A2.25 2.25 0 0 1 21 23.248H3A2.25 2.25 0 0 1 .749 21V3A2.25 2.25 0 0 1 3 .748h14.377a1.5 1.5 0 0 1 1.061.439Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M17.249.748v4.5a1.5 1.5 0 0 0 1.5 1.5h4.5"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
