import '@keyliving/component-lib/dist/css/global.css';
import './scss/app.scss';

import { withProfiler, wrapUseRoutes } from '@sentry/react';
import { useRoutes } from 'react-router-dom';

import HydrationGate from './components/HydrationGate';
import AppModals from './components/Modals';
import PrivateRoute from './components/PrivateRoute';
import { authRoutes, publicRoutes } from './routes/routes';

const useSentryRoutes = wrapUseRoutes(useRoutes);

function App() {
    const routes = useSentryRoutes([
        ...publicRoutes,
        {
            /**
             * Our authRoutes are wrapped in the PrivateRoute component so if a user
             * navigates to them and is not logged in, they are redirected to the
             * login page vs getting a 404 if we didn't create them.
             */
            path: '/',
            element: <PrivateRoute />,
            children: authRoutes,
        },
    ]);

    return (
        <HydrationGate>
            <>
                {routes}
                <AppModals />
            </>
        </HydrationGate>
    );
}

export default withProfiler(App);
