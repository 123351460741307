import { Claims, Owner } from '@keyliving/shared-types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { AuthState } from './types';

export const initialAuthState: AuthState = { user: null, claims: null, token: null };

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        setAuthDetails: (state, action: PayloadAction<AuthState>) => {
            const { claims, token, user } = action.payload;
            Object.assign(state, { user, claims, token });
        },
        clearAuthDetails: () => {
            return initialAuthState;
        },
        setCurrentUser: (state, action: PayloadAction<Owner>) => {
            state.user = action.payload;
        },
        setClaims: (state, action: PayloadAction<Claims>) => {
            state.claims = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { clearAuthDetails, setAuthDetails, setClaims, setCurrentUser } = authSlice.actions;

export default authSlice.reducer;
