import { lazy } from 'react';

const Welcome = lazy(() => import('./Private/Welcome'));

// Shown only to authenticated users
const PRIVATE_MODALS = {
    welcome: Welcome,
} as const;

// Shown to everyone
export const PUBLIC_MODALS = {} as const;

export const allModals = {
    ...PRIVATE_MODALS,
    ...PUBLIC_MODALS,
};
