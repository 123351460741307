import type { SelectOption } from '@keyliving/component-lib';
import { eachMonthOfInterval, format, subMonths } from 'date-fns';

const now = new Date();

/**
 * Generates an array of SelectOption's that include options for
 * the current Year To Date and the previous three months
 */
export default function generateUtcYtdFilterOptions(): SelectOption[] {
    // NOTE: startDate is inclusive, endDate is exclusive
    // const startOfYear = new Date(Date.UTC(now.getFullYear(), 0, 1));
    // const startOfNextMonth = new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 1));

    const options: SelectOption[] = [
        // TODO: Removed for now until we have historical data. Reinstate at a later date (pun intended and it rhymes).
        // {
        //     label: 'YTD',
        //     value: `${startOfYear.toISOString()},${startOfNextMonth.toISOString()}}`,
        // },
    ];

    // Array of months
    const months = eachMonthOfInterval({
        // Last 3 months
        start: subMonths(now, 3),
        // End with current month
        end: now,
    }).reverse();

    for (const month of months) {
        const startOfMonth = new Date(Date.UTC(month.getFullYear(), month.getMonth(), 1));
        /**
         * Add one month. Out of bounds months will roll over to next month.
         * e.g. 11th month (zero based === dec) + 1 = 12th month which equates to Jan the next year.
         */
        const startOfNextMonth = new Date(Date.UTC(month.getFullYear(), month.getMonth() + 1, 1));

        options.push({
            label: format(month, 'MMM yyyy'),
            value: `${startOfMonth.toISOString()},${startOfNextMonth.toISOString()}`,
        });
    }

    return options;
}
