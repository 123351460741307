import { Asset } from '@keyliving/shared-types';
import { createEntityAdapter } from '@reduxjs/toolkit';

import { Equity } from './types';

export const equityAdapter = createEntityAdapter<Equity>({
    selectId: (equity) => equity.asset.id,
});

export const assetAdapter = createEntityAdapter<Asset>({
    selectId: (asset) => asset.id,
});
