import { route, types, union } from 'react-router-typesafe-routes/dom';
import { zod } from 'react-router-typesafe-routes/zod';
import { string } from 'zod';

import { assetTabs } from '../components/Assets/AssetDetail/constants';
import { allModals } from '../components/Modals/constants';

const modalNames = Object.keys(allModals) as Array<keyof typeof allModals>;

export const APP_MODAL_PARAMS = route('', {
    searchParams: { modal: union(modalNames) },
});

export const URLS = {
    Assets: route('assets', types(APP_MODAL_PARAMS), {
        Detail: route(':id', {
            searchParams: {
                tab: union(assetTabs.options).default(assetTabs.enum.performance),
            },
        }),
    }),
    ClaimAccount: route('claim-account/:token', types(APP_MODAL_PARAMS)),
    Documents: route(
        'documents',
        types({
            searchParams: {
                folder: zod(string()),
            },
        })(APP_MODAL_PARAMS)
    ),
    ForgotPassword: route(
        'forgot-password',
        {},
        {
            Success: route('success'),
        }
    ),
    Login: route('login', {
        searchParams: {
            token: zod(string()),
        },
    }),
    NewPassword: route('new-password/:token'),
    Portfolio: route('portfolio', types(APP_MODAL_PARAMS)),
};
