import { useGlobalAuth } from '@keyliving/component-lib';
import { Claims } from '@keyliving/shared-types';
import { isTokenExpired } from '@keyliving/utils';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { captureException } from '@sentry/react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { authService, setAuthDetails, setCurrentUser } from '../redux/modules/auth';
import { AppStore, setupStore } from '../redux/store';
import FullScreenFallbackLayout from './layout/FullScreenFallbackLayout';

interface HydrationGateProps {
    children: ReactElement | null;
}

let store: null | AppStore = null;

async function verifyToken(store: AppStore, token: string): Promise<Claims> {
    try {
        // Verify the token...
        const claims = await store
            .dispatch(authService.endpoints.verifyAuthToken.initiate(token))
            .unwrap();

        // ...token expiry should be handled by the api, but to be safe...
        const isExpired = isTokenExpired(claims.exp);

        if (isExpired) {
            throw new Error('Token expired');
        }

        return Promise.resolve(claims);
    } catch (error) {
        return Promise.reject('Token has expired');
    }
}

/**
 * Create the store and if we have a valid token, pre-populate
 * the token and claims.
 *
 * @param authToken authToken taken from Auth Cookie
 * @param impersonationToken authToken taken from session storage
 * @returns the store and a flag indicating if we should hydrate
 */
async function createStore(
    authToken: string | null = null,
    impersonationToken: string | null
): Promise<{ mintedStore: AppStore; shouldHydrate: boolean }> {
    // If we don't have an authToken, we aren't logged in...
    if (!authToken) {
        // ...return a clean slate
        return { mintedStore: setupStore(), shouldHydrate: false };
    }

    // Create the store with the auth token
    const store = setupStore({
        auth: { token: authToken, claims: null, user: null },
    });

    try {
        const tokensToVerify = [verifyToken(store, authToken)];

        if (impersonationToken) {
            tokensToVerify.push(verifyToken(store, impersonationToken));
        }

        const [authUserClaims, impersonationClaims] = await Promise.all(tokensToVerify);

        let claims = authUserClaims;
        let token = authToken;

        if (impersonationClaims && impersonationToken) {
            const adminId = authUserClaims.id;
            const impersonatorId = impersonationClaims.act?.sub;

            /**
             * We've tried to use a token that we aren't authenticated to use
             */
            if (!impersonatorId || impersonatorId !== adminId) {
                /**
                 * Maybe a low chance this is helpful but might alert us if someone
                 * is trying to do something nefarious.
                 */
                captureException(new Error(`[Impersonation]: Unauthorized to impersonate user`), {
                    tags: {
                        page: 'Impersonation',
                    },
                    extra: {
                        // User who is logged in via auth cookie
                        adminUserId: adminId,
                        // The "actor" on the impersonation token
                        impersonatorId,
                        // User the impersonation token is for
                        impersonatedUserId: impersonationClaims.id,
                    },
                });

                throw new Error(
                    'The actor user.id on the impersonation token does not match the user.id of the person who is trying to impersonate them'
                );
            }

            /**
             * If we are impersonating a user, we set our state to the impersonated users
             * token and claims.
             */
            claims = impersonationClaims;
            token = impersonationToken;
        }

        store.dispatch(
            setAuthDetails({
                claims,
                token,
                user: null,
            })
        );

        return {
            mintedStore: store,
            shouldHydrate: true,
        };
    } catch (error) {
        // return a clean slate
        return { mintedStore: setupStore(), shouldHydrate: false };
    }
}

export default function HydrationGate({ children }: HydrationGateProps) {
    const [canMount, setCanMount] = useState<boolean>(false);
    const { token: authToken } = useGlobalAuth();

    const hydrateCurrentUser = useCallback(async (store: AppStore) => {
        try {
            const user = await store.dispatch(authService.endpoints.me.initiate()).unwrap();
            store.dispatch(setCurrentUser(user));

            return Promise.resolve();
        } catch (error) {
            /**
             * Just swallow the error for now. When the app mounts it will
             * redirect to the /login route without a user
             */
            return Promise.reject();
        }
    }, []);

    const hydrateApp = useCallback(
        (store: AppStore) => {
            Promise.allSettled([hydrateCurrentUser(store)]).finally(() => {
                setCanMount(true);
            });
        },
        [hydrateCurrentUser]
    );

    const initializeStore = useCallback(async () => {
        const impersonationToken = sessionStorage.getItem('impersonationToken');

        const { mintedStore, shouldHydrate } = await createStore(authToken, impersonationToken);
        store = mintedStore;

        // required for refetchOnFocus/refetchOnReconnect behaviors
        setupListeners(store.dispatch);

        if (shouldHydrate) {
            hydrateApp(store);
        } else {
            setCanMount(true);
        }
    }, [hydrateApp, authToken]);

    useEffect(() => {
        initializeStore();

        // Only once on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!canMount || store === null) {
        return <FullScreenFallbackLayout isLoading />;
    }

    return <ReduxProvider store={store}>{children}</ReduxProvider>;
}
